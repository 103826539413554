export enum LogType {
  CUSTOM_PRE_EVAL = '/custom-pre-eval',
  CUSTOM_OPTIMIZATION = '/custom-optimization',
  CUSTOM_EFFICIENCY_LEVERS = '/custom-efficiency-levers',
  CUSTOM_CREATE_SCENARIO = '/custom-create-scenario',
  CUSTOM_LOGIN = '/custom-login',
  CUSTOM_POST_EVAL = '/custom-post-eval',
  CUSTOM_RME_VALIDATION_UNVALIDATION = '/custom-rme-validation-unvalidation',
  PROMOTIONS_IMPACT_ROI = '/promotions/impact-roi'
}

export const LogsMap: { [key in LogType]: string } = {
  [LogType.CUSTOM_PRE_EVAL]: 'a visité une opération pre eval',
  [LogType.CUSTOM_OPTIMIZATION]: 'a visité le module optimisation',
  [LogType.CUSTOM_EFFICIENCY_LEVERS]: 'a visité le module efficiency levers',
  [LogType.CUSTOM_CREATE_SCENARIO]: 'a créé un scénario',
  [LogType.CUSTOM_LOGIN]: "s'est connecté",
  [LogType.CUSTOM_POST_EVAL]: 'a visité une opération post eval',
  [LogType.CUSTOM_RME_VALIDATION_UNVALIDATION]: 'a validé/dévalidé une opération',
  [LogType.PROMOTIONS_IMPACT_ROI]: 'a visité le module efficiency levers'
};

export interface ILog {
  id: string;
  user: any;
  type: string;
  action: string | null;
  promotionId?: string;
  validationType?: 'Validation' | 'Dévalidation';
  evalType?: 'pre-eval' | 'post-eval';
  brandId?: string;
  scenarioName?: string;
  scenarioType?: string;
  data?: any;
}

export interface ICreateLog {
  user: any;
  type: LogType;
  action: string | null;
  promotionId?: string;
  validationType?: 'Validation' | 'Dévalidation';
  evalType?: 'pre-eval' | 'post-eval';
  brandId?: string;
  scenarioName?: string;
  scenarioType?: string;

  // Custom data
  data?: any;
}
